<template> 
  <div class="jobswipe">

    <!-- App Download Promotion Section -->
    <AppDownload />

    <!-- Search Section -->
    <section class="search-section-container">
      <div class="search-section">
        <form @submit.prevent="executeSearch" class="search-form">
          <h2 class="search-title">Find Your Next Opportunity</h2>
          <!-- Job Title Search -->
          <div class="search-field">
            <label class="search-section-label">Job Title</label>
            <div class="search-input-wrapper">
              <input
                v-model="searchQueries.title"
                type="text"
                placeholder="Search job titles, keywords, or companies"
                class="search-input"
              />
              <span class="search-icon">
                <SearchIcon />
              </span>
            </div>
          </div>

          <!-- Location Filter -->
          <div class="search-field">
            <label class="search-section-label">Location</label>
            <div class="search-input-wrapper">
              <LocationFilter
                @update:location="handleLocationUpdate"
                @error="handleLocationError"
              />
            </div>
          </div>

          <!-- Salary Range -->
          <div class="search-field">
            <label class="search-section-label">Salary Range</label>
            <div class="salary-range">
              <div class="search-field">
                <label class="search-label">Min Salary</label>
                <div class="search-input-wrapper">
                  <select v-model="filters.salaryMin" class="search-select">
                    <option value="30000">$30,000</option>
                    <option value="40000">$40,000</option>
                    <option value="50000">$50,000</option>
                    <option value="60000">$60,000</option>
                    <option value="70000">$70,000</option>
                    <option value="80000">$80,000</option>
                    <option value="100000">$100,000</option>
                    <option value="120000">$120,000</option>
                    <option value="140000">$140,000</option>
                    <option value="175000">$175,000</option>
                    <option value="200000">$200,000</option>
                  </select>
                  <span class="search-icon">
                    <DollarSignIcon />
                  </span>
                </div>
              </div>

              <div class="search-field">
                <label class="search-label">Max Salary</label>
                <div class="search-input-wrapper">
                  <select v-model="filters.salaryMax" class="search-select">
                    <option value="30000">$30,000</option>
                    <option value="40000">$40,000</option>
                    <option value="50000">$50,000</option>
                    <option value="60000">$60,000</option>
                    <option value="70000">$70,000</option>
                    <option value="80000">$80,000</option>
                    <option value="100000">$100,000</option>
                    <option value="120000">$120,000</option>
                    <option value="140000">$140,000</option>
                    <option value="175000">$175,000</option>
                    <option value="200000">$200,000</option>
                    <option value="400000">$200,000+</option>
                  </select>
                  <span class="search-icon">
                    <DollarSignIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Job Type and Date Posted -->
          <div class="search-grid">
            <div class="search-field">
              <label class="search-section-label">Job Type</label>
              <div class="search-input-wrapper">
                <select v-model="filters.jobType" class="search-select">
                  <option value="">All Types</option>
                  <option value="Full-Time">Full Time</option>
                  <option value="Part-Time">Part Time</option>
                  <option value="Contract">Contract</option>
                  <option value="Internship">Internship</option>
                </select>
                <span class="search-icon">
                  <BriefcaseIcon />
                </span>
              </div>
            </div>

            <div class="search-field">
              <label class="search-section-label">Date Posted</label>
              <div class="search-input-wrapper">
                <select v-model="filters.datePosted" class="search-select">
                  <option value="1">Last 24 Hours</option>
                  <option value="3">Last 3 Days</option>
                  <option value="7">Last 7 Days</option>
                  <option value="14">Last 14 Days</option>
                  <option value="30">Last 30 Days</option>
                </select>
                <span class="search-icon">
                  <CalendarIcon />
                </span>
              </div>
            </div>
          </div>

          <button 
            type="submit"
            class="search-button"
            :disabled="loading"
          >
            {{ loading ? 'Searching...' : 'Search Jobs' }}
          </button>
        </form>
      </div>

      <div v-if="error" class="error-modal">
        <div class="error-modal__content">
          <p>{{ error }}</p>
          <button @click="closeErrorModal" class="error-modal__button">Okay</button>
        </div>
      </div>
    </section>
    <!-- End of Search Section -->

    <main class="jobswipe__main">
      <div v-if="loading" class="jobs-loading">
        <div v-for="n in 3" :key="n" class="jobs-loading__card">
          <div class="jobs-loading__title"></div>
          <div class="jobs-loading__subtitle"></div>
          <div class="jobs-loading__text-short"></div>
          <div class="jobs-loading__text-long"></div>
        </div>
      </div>

      <div v-else-if="error" class="jobs-error">
        {{ error }}
      </div>

      <div v-else-if="displayedJobs.length === 0" class="jobs-empty">
        No jobs found. Try adjusting your search criteria.
      </div>

      <div v-else class="jobs-grid">
        <div v-for="job in displayedJobs" :key="job.docId" class="job-card">
          <div class="job-card__header">
            <div>
              <h2 class="job-card__title">{{ job.title }}</h2>
              <p class="job-card__company">{{ job.company }}</p>
            </div>
            <div class="job-card__actions">
              <button class="job-card__bookmark">
                <BookmarkIcon />
              </button>
            </div>
          </div>

          <div class="job-card__details">
            <p><MapPinIcon class="icon-class" /> {{ job.location }}</p>
            <p><DollarSignIcon class="icon-class" /> ${{ job.salaryRange.min.toLocaleString() }} - ${{ job.salaryRange.max.toLocaleString() }}</p>
            <p><BriefcaseIcon class="icon-class" /> {{ job.type }}</p>
            <p v-if="job.remote || job.globallyRemote">
              <GlobeIcon class="icon-class" /> {{ job.globallyRemote ? 'Nationally Remote' : 'Remote' }}
            </p>
          </div>

          <div class="job-card__skills">
            <p class="job-card__skills-title">Required Skills:</p>
            <div class="job-card__skills-list">
              <span
                v-for="(skill, index) in job.requiredSkills"
                :key="index"
                class="job-card__skill-tag"
              >
                {{ skill.skill }}
              </span>
            </div>
          </div>

          <div v-if="job.desiredSkills && job.desiredSkills.length > 0" class="job-card__skills">
            <p class="job-card__skills-title">Desired Skills:</p>
            <div class="job-card__skills-list">
              <span
                v-for="(skill, index) in job.desiredSkills"
                :key="`desired-${index}`"
                class="job-card__skill-tag"
              >
                {{ skill.skill }}
              </span>
            </div>
          </div>

          <button 
            @click="viewJobDetails(job)"
            class="job-card__cta"
          >
            View Details
          </button>
        </div>
      </div>

      <div v-if="hasMoreJobs && displayedJobs.length > 0" class="load-more">
        <button
          @click="loadMore"
          class="load-more__button"
          :disabled="loading"
        >
          {{ loading ? 'Loading...' : 'Load More Jobs' }}
        </button>
      </div>

      <div 
        v-if="selectedJob" 
        class="job-modal-overlay"
        @click="closeJobDetails"
      >
        <div 
          class="job-modal__content"
          @click.stop
        >
          <div class="job-modal__header">
            <div>
              <h2 class="job-modal__title">{{ selectedJob.title }}</h2>
              <p class="job-modal__company">{{ selectedJob.company }}</p>
            </div>
            <button 
              @click="closeJobDetails"
              class="job-modal__close"
            >
              <XIcon />
            </button>
          </div>

          <div class="job-modal__details">
            <div class="job-modal__info">
              <p><MapPinIcon class="icon-class" /> {{ selectedJob.location }}</p>
              <p><DollarSignIcon class="icon-class" /> ${{ selectedJob.salaryRange.min.toLocaleString() }} - ${{ selectedJob.salaryRange.max.toLocaleString() }}</p>
              <p><BriefcaseIcon class="icon-class" /> {{ selectedJob.type }}</p>
              <p v-if="selectedJob.remote || selectedJob.globallyRemote">
                <GlobeIcon class="icon-class" /> {{ selectedJob.globallyRemote ? 'Nationally Remote' : 'Remote' }}
              </p>
              <p><CalendarIcon class="icon-class" /> Posted {{ formatDate(selectedJob.datePosted) }}</p>
            </div>

            <div class="job-modal__section">
              <h3 class="job-modal__subtitle">Description</h3>
              <div class="job-modal__description" v-html="formatDescription(selectedJob.description)"></div>
            </div>

            <div class="job-modal__section">
              <h3 class="job-modal__subtitle">Required Skills</h3>
              <div class="job-modal__skills">
                <span
                  v-for="(skill, index) in selectedJob.requiredSkills"
                  :key="index"
                  class="job-modal__skill-tag"
                >
                  {{ skill.skill }}
                </span>
              </div>
            </div>

            <div v-if="selectedJob.desiredSkills?.length" class="job-modal__section">
              <h3 class="job-modal__subtitle">Desired Skills</h3>
              <div class="job-modal__skills">
                <span
                  v-for="(skill, index) in selectedJob.desiredSkills"
                  :key="`desired-${index}`"
                  class="job-modal__skill-tag"
                >
                  {{ skill.skill }}
                </span>
              </div>
            </div>

            <div class="job-modal__actions">
              <button 
                class="job-modal__apply"
                @click="applyToJob(selectedJob.docId)"
              >
                Apply Now
              </button>

              <button 
                class="job-modal__share"
                @click="shareJob(selectedJob)"
              >
                <ShareIcon />
                Share
              </button>
              <button 
                class="job-modal__close-button"
                @click="closeJobDetails"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { 
  collection, 
  query, 
  where, 
  getDocs,
  limit,
  startAfter,
  orderBy
} from "firebase/firestore";
import LocationFilter from '@/components/LocationFilter.vue';
import { marked } from 'marked';
import { 
  SearchIcon, 
  MapPinIcon, 
  BriefcaseIcon,
  DollarSignIcon,
  CalendarIcon,
  XIcon,
  BookmarkIcon,
  GlobeIcon,
  ShareIcon
} from 'lucide-vue-next';
import AppDownload from '@/components/AppDownload.vue';

export default {
  name: 'HomePage',
  components: {
    LocationFilter,
    AppDownload,
    SearchIcon,
    MapPinIcon,
    BriefcaseIcon,
    DollarSignIcon,
    CalendarIcon,
    XIcon,
    BookmarkIcon,
    GlobeIcon,
    ShareIcon
  },

  data() {
  return {
    searchQueries: {
      title: "",
      skills: ""
    },
    filters: {
      jobType: "",
      salaryMin: "50000",
      salaryMax: "100000",
      datePosted: "30",
    },
    locationFilter: {
      city: "",
      state: "",
      coordinates: null,
      radius: 30,
      searchRange: null,
      isRemoteOnly: false
    },
    jobs: [],
    displayedJobs: [],
    loading: false,
    error: null,
    pageSize: 20,
    selectedJob: null,
    showCopyPopup: false,
    batchLimit: 50,
    
    // Pagination states
    exactLastVisible: null,
    partialLastVisible: null,
    fallbackLastVisible: null,
    moreExactAvailable: true,
    morePartialAvailable: true,
    moreFallbackAvailable: true,
    
    // New property to track unique jobs
    seenJobKeys: new Set()
  };
},

  computed: {
    hasActiveFilters() {
      return this.locationFilter.city || 
             this.filters.jobType || 
             this.filters.salaryMin || 
             this.filters.salaryMax ||
             this.filters.datePosted;
    },
    isLocationValid() {
      return this.locationFilter.city && this.locationFilter.state;
    },
    hasMoreJobs() {
      return this.moreExactAvailable || this.morePartialAvailable || this.moreFallbackAvailable;
    }
  },

  methods: {
    closeErrorModal() {
      this.error = null;
    },

    handleLocationUpdate(locationData) {
      this.locationFilter = locationData;
    },

    handleLocationError(error) {
      this.error = error;
    },

    viewJobDetails(job) {
      this.selectedJob = job;
    },

    closeJobDetails() {
      this.selectedJob = null;
    },

    applyToJob(docId) {
      if (docId) {
        const url = this.$router.resolve({ name: 'JobApply', params: { docId: docId } }).href;
        window.open(url, '_blank');
      } else {
        console.error("Job document ID is not available.");
      }
    },

    shareJob(job) {
      const jobUrl = `${window.location.origin}/apply/${job.docId}`;
      navigator.clipboard.writeText(jobUrl)
        .then(() => {
          this.showCopyPopup = true;
          setTimeout(() => {
            this.showCopyPopup = false;
          }, 2000);
        })
        .catch((error) => {
          console.error("Failed to copy job URL: ", error);
        });
    },

    formatDescription(description) {
      if (!description) return '';
      
      const formattedText = description
        .replace(/\\n\\n/g, '\n\n')
        .replace(/\\n/g, '\n')
        .replace(/\*\*/g, '**');

      marked.setOptions({
        breaks: true,
        gfm: true
      });
      
      return marked(formattedText);
    },

    async executeSearch() {
      if (!this.isLocationValid && !this.locationFilter.isRemoteOnly) {
        this.error = "Please select both a city and a state to search.";
        return;
      }

      this.error = null;
      this.loading = true;
      this.jobs = [];
      this.displayedJobs = [];

      // Reset pagination states
      this.exactLastVisible = null;
      this.partialLastVisible = null;
      this.fallbackLastVisible = null;
      this.moreExactAvailable = true;
      this.morePartialAvailable = true;
      this.moreFallbackAvailable = true;

      try {
        await this.fetchJobsBatch();
        this.displayedJobs = this.jobs.slice(0, this.pageSize);
      } catch (error) {
        console.error("Error fetching jobs:", error);
        this.error = "Error fetching jobs: " + error.message;
      } finally {
        this.loading = false;
      }
    },

    async loadMore() {
      if (!this.hasMoreJobs) return; // No more data
      this.loading = true;
      try {
        await this.fetchJobsBatch();
        this.displayedJobs = this.jobs.slice(0, this.displayedJobs.length + this.pageSize);
      } catch (error) {
        console.error("Error loading more jobs:", error);
        this.error = "Error loading more jobs: " + error.message;
      } finally {
        this.loading = false;
      }
    },

    async fetchJobsBatch() {
  // Get the search term and normalize it
  const searchTerm = this.searchQueries.title.toLowerCase().trim();
  let combinedNewJobs = [];

  // First, fetch exact matches if we have a search term
  if (searchTerm && this.moreExactAvailable) {
    const exactSnapshot = await this.runExactQuery(searchTerm, this.exactLastVisible);
    
    // Update pagination state for exact matches
    if (exactSnapshot.docs.length < this.batchLimit) {
      this.moreExactAvailable = false;
    } else {
      this.exactLastVisible = exactSnapshot.docs[exactSnapshot.docs.length - 1];
    }
    
    // Convert the snapshot documents to job objects
    const exactMatchJobs = exactSnapshot.docs.map(doc => ({
      docId: doc.id,
      ...doc.data(),
      relevanceTier: 3
    }));
    combinedNewJobs.push(...exactMatchJobs);
  }

  // Then fetch partial matches if needed
  if (searchTerm && this.morePartialAvailable && combinedNewJobs.length < this.batchLimit) {
    const partialSnapshot = await this.runPartialQuery(searchTerm, this.partialLastVisible);
    
    // Update pagination state for partial matches
    if (partialSnapshot.docs.length < this.batchLimit) {
      this.morePartialAvailable = false;
    } else {
      this.partialLastVisible = partialSnapshot.docs[partialSnapshot.docs.length - 1];
    }
    
    // Convert the snapshot documents to job objects
    const partialMatchJobs = partialSnapshot.docs.map(doc => ({
      docId: doc.id,
      ...doc.data(),
      relevanceTier: 2
    }));
    combinedNewJobs.push(...partialMatchJobs);
  }

  // Finally fetch fallback results if we still need more
  if (this.moreFallbackAvailable && combinedNewJobs.length < this.batchLimit) {
    const fallbackSnapshot = await this.runFallbackQuery(this.fallbackLastVisible);
    
    // Update pagination state for fallback matches
    if (fallbackSnapshot.docs.length < this.batchLimit) {
      this.moreFallbackAvailable = false;
    } else {
      this.fallbackLastVisible = fallbackSnapshot.docs[fallbackSnapshot.docs.length - 1];
    }
    
    // Convert the snapshot documents to job objects
    const fallbackMatchJobs = fallbackSnapshot.docs.map(doc => ({
      docId: doc.id,
      ...doc.data(),
      relevanceTier: 1
    }));
    combinedNewJobs.push(...fallbackMatchJobs);
  }

  // Apply any client-side filters (salary, date, location)
  const filteredJobs = this.clientSideFilter(combinedNewJobs);

  // Create a map to store unique jobs, using title + company as the key
  const uniqueJobsMap = new Map();

  // Process both existing and new jobs
  [...this.jobs, ...filteredJobs].forEach(job => {
    // Create a unique key by combining title and company (normalized to lowercase)
    const jobKey = `${job.title.toLowerCase()}-${job.company.toLowerCase()}`;
    
    // Keep the job if we haven't seen it before, or if this version is newer
    if (!uniqueJobsMap.has(jobKey) || 
        job.datePosted.toDate() > uniqueJobsMap.get(jobKey).datePosted.toDate()) {
      uniqueJobsMap.set(jobKey, job);
    }
  });

  // Convert the map back to an array and sort by date (newest first)
  const uniqueJobs = Array.from(uniqueJobsMap.values());
  uniqueJobs.sort((a, b) => b.datePosted.toDate() - a.datePosted.toDate());

  // Update the jobs array with our deduplicated results
  this.jobs = uniqueJobs;
},

    async runExactQuery(searchTerm, startDoc) {
      // Exact match: titleLowerCase == searchTerm
      const constraints = this.buildBaseConstraints();
      constraints.push(where("titleLowerCase", "==", searchTerm));
      const q = this.buildQuery(constraints, startDoc);
      return await getDocs(q);
    },

    async runPartialQuery(searchTerm, startDoc) {
      // Partial match: titleLowerCase >= searchTerm && titleLowerCase <= searchTerm + '\uf8ff'
      const constraints = this.buildBaseConstraints();
      constraints.push(where("titleLowerCase", ">=", searchTerm));
      constraints.push(where("titleLowerCase", "<=", searchTerm + "\uf8ff"));
      // We'll order by titleLowerCase for partial search
      const q = this.buildQuery(constraints, startDoc, "titleLowerCase");
      return await getDocs(q);
    },

    async runFallbackQuery(startDoc) {
      // Fallback: no title match constraints, just base constraints
      const constraints = this.buildBaseConstraints();
      const q = this.buildQuery(constraints, startDoc);
      return await getDocs(q);
    },

    buildBaseConstraints() {
      const constraints = [];
      constraints.push(where("isActive", "==", true));

      if (this.filters.jobType) {
        constraints.push(where("type", "==", this.filters.jobType));
      }

      // Date filter
      const daysAgo = parseInt(this.filters.datePosted);
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - daysAgo);
      constraints.push(where("datePosted", ">=", cutoffDate));

      // Salary constraints
      if (this.filters.salaryMin) {
        constraints.push(where("salaryRange.max", ">=", parseInt(this.filters.salaryMin)));
      }
      if (this.filters.salaryMax) {
        constraints.push(where("salaryRange.min", "<=", parseInt(this.filters.salaryMax)));
      }

      // Remote constraints
      if (this.locationFilter.isRemoteOnly) {
        // If remote only, we don't filter further here; just rely on fallback
      } else if (this.locationFilter.state) {
        // If we have a state, we let client-side handle location bounding
      }

      return constraints;
    },

    buildQuery(constraints, startDoc, orderField = "datePosted", orderDirection = "desc") {
      let baseQuery = query(
        collection(db, "jobs"),
        ...constraints,
        orderBy(orderField, orderDirection),
        limit(this.batchLimit)
      );
      if (startDoc) {
        baseQuery = query(
          collection(db, "jobs"),
          ...constraints,
          orderBy(orderField, orderDirection),
          startAfter(startDoc),
          limit(this.batchLimit)
        );
      }
      return baseQuery;
    },

    clientSideFilter(jobs) {
      const daysAgo = parseInt(this.filters.datePosted);
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - daysAgo);

      return jobs.filter(job => {
        const matchesSalary = (!this.filters.salaryMin || job.salaryRange.max >= this.filters.salaryMin) &&
                              (!this.filters.salaryMax || job.salaryRange.min <= this.filters.salaryMax);

        const matchesDatePosted = !job.datePosted || job.datePosted.toDate() >= cutoffDate;

        let matchesLocation = true;
        if (this.locationFilter.coordinates && 
            !this.locationFilter.isRemoteOnly && 
            !job.remote && 
            !job.globallyRemote) {
          const jobLat = job.coordinates?.latitude;
          const jobLon = job.coordinates?.longitude;
          matchesLocation = jobLat >= this.locationFilter.searchRange.latMin &&
                            jobLat <= this.locationFilter.searchRange.latMax &&
                            jobLon >= this.locationFilter.searchRange.lonMin &&
                            jobLon <= this.locationFilter.searchRange.lonMax;
        }

        return matchesSalary && matchesDatePosted && matchesLocation;
      });
    },

    computeMatchScore(jobTitle, searchTitle) {
      if (!searchTitle) {
        return 0;
      }
      const jobTitleLower = jobTitle.toLowerCase();
      const searchTitleLower = searchTitle.toLowerCase();

      if (jobTitleLower === searchTitleLower) {
        return 3;
      } else if (jobTitleLower.includes(searchTitleLower)) {
        return 2;
      } else {
        const titleTerms = searchTitleLower.split(' ').filter(term => term.length > 0);
        if (titleTerms.every(term => jobTitleLower.includes(term))) {
          return 1;
        } else {
          return 0;
        }
      }
    },

    formatDate(timestamp) {
      if (!timestamp) return '';
      const date = timestamp.toDate();
      const now = new Date();
      const diffTime = Math.abs(now - date);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays === 0) return 'Today';
      if (diffDays === 1) return 'Yesterday';
      if (diffDays < 7) return `${diffDays} days ago`;
      
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    },
  }
};
</script>

<style>
* {
  box-sizing: border-box;
}

body, .jobswipe {
  font-family: 'FixelDisplay', sans-serif;
  background-color: #f3ede2;
  margin: 0;
  padding: 0;
}

/* Hero Section */
.jobswipe__hero {
  background-color: #f3ede2;
  text-align: center;
  padding: 4rem 1rem 2rem;
}

.hero-content {
  max-width: 600px;
  margin: 0 auto;
}

.hero-logo {
  width: 200px;
  margin-bottom: 1.5rem;
}

.hero-title {
  font-size: 3rem;
  color: #fc7115;
  margin-bottom: 1rem;
  font-weight: 700;
}

.hero-subtitle {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 2rem;
}

/* Search Section */
.search-section-container {
  max-width: 72rem;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
}

.search-section {
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  padding: 2rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 2px rgb(0 0 0 / 0.05);
}

.search-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.search-title {
  font-size: 2rem;
  font-weight: 700;
  color: #fc7115;
  margin-bottom: 2rem;
}

.search-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Added spacing after the Location field */
.search-form > .search-field:nth-of-type(2) {
  margin-bottom: 2rem;
}

.search-section-label {
  font-size: 1.125rem;
  font-weight: 700;
  color: #333;
  margin-top: 1rem;
}

.search-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #666;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input,
.search-select {
  width: 100%;
  padding: 0.875rem 1rem;
  padding-left: 2.5rem;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.5rem;
  background-color: white;
  outline: none;
  font-size: 1rem;
}

/* iOS Select Fixes */
.search-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%239ca3af' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1em;
  padding-right: 2.5rem;
}

.search-input:focus,
.search-select:focus {
  border-color: rgb(59, 130, 246);
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
}

.search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgb(156, 163, 175);
  width: 1.25rem;
  height: 1.25rem;
  pointer-events: none;
  z-index: 1;
}

/* iOS-specific fixes */
@supports (-webkit-touch-callout: none) {
  .search-select {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    text-indent: 0;
  }
  
  .search-icon {
    z-index: 2;
  }
}

.search-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 640px) {
  .search-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.salary-range {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 640px) {
  .salary-range {
    grid-template-columns: repeat(2, 1fr);
  }
}

.search-button {
  padding: 0.75rem 1.5rem;
  background-color: #fc7115;
  color: white;
  font-weight: 500;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
  width: fit-content;
  align-self: flex-start;
}

.search-button:hover:not(:disabled) {
  background-color: #e35d0a;
}

/* Errors */
.error-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.error-modal__content {
  background: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
}

.error-modal__button {
  background-color: #fc7115;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

/* Main Content */
.jobswipe__main {
  max-width: 72rem;
  margin: 0 auto;
  padding: 1.5rem 1rem;
}

/* Loading, Error, and Empty States */
.jobs-loading {
  display: grid;
  gap: 1rem;
}

.jobs-loading__card {
  background: #fff;
  border-radius: 0.5rem;
  height: 150px;
  padding: 1rem;
  animation: pulse 1.5s infinite ease-in-out;
}

.jobs-error,
.jobs-empty {
  text-align: center;
  font-size: 1.25rem;
  color: #333;
}

/* Jobs Grid */
.jobs-grid {
  display: grid;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .jobs-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .jobs-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Job Card */
.job-card {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  transition: box-shadow 0.2s;
}

.job-card:hover {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.job-card__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.job-card__title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #fc7115;
}

.job-card__title:hover {
  text-decoration: underline;
}

.job-card__company {
  font-weight: 500;
  color: #333;
  margin-top: 0.25rem;
}

.job-card__details {
  color: #333;
  margin-bottom: 1.5rem;
}

.job-card__details > p {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.job-card__skills-title {
  font-weight: 500;
  color: #333;
  margin-bottom: 0.5rem;
}

.job-card__skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.job-card__skill-tag {
  background-color: rgb(243, 244, 246);
  color: rgb(55, 65, 81);
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}

.job-card__cta {
  width: 100%;
  background-color: #fc7115;
  color: white;
  font-weight: 500;
  padding: 0.75rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
}

.job-card__cta:hover {
  background-color: #e35d0a;
}

/* Job Modal Overlay */
.job-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Job Modal Content */
.job-modal__content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.job-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job-modal__title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fc7115;
}

.job-modal__company {
  font-weight: 500;
  color: #333;
  margin-top: 0.25rem;
}

.job-modal__close {
  background: none;
  border: none;
  cursor: pointer;
}

.job-modal__details {
  margin-top: 1.5rem;
}

.job-modal__info > p {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.job-modal__section {
  margin-top: 1.5rem;
}

.job-modal__subtitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.job-modal__description {
  color: #555;
}

.job-modal__skills {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.job-modal__skill-tag {
  background-color: rgb(243, 244, 246);
  color: rgb(55, 65, 81);
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}

/* Modal Actions */
.job-modal__actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.job-modal__apply,
.job-modal__share,
.job-modal__close-button {
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  border-radius: 0.5rem;
  cursor: pointer;
}

.job-modal__apply {
  background-color: #fc7115;
  color: white;
  border: none;
}

.job-modal__apply:hover {
  background-color: #e35d0a;
}

.job-modal__share {
  background-color: #3b82f6;
  color: white;
  border: none;
}

.job-modal__share:hover {
  background-color: #2563eb;
}

.job-modal__close-button {
  background-color: #6b7280;
  color: white;
  border: none;
}

.job-modal__close-button:hover {
  background-color: #4b5563;
}

.icon-class {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-right: 0.5rem;
}

/* Load More */
.load-more {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.load-more__button {
  background-color: #fc7115;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
}

.load-more__button:hover {
  background-color: #e35d0a;
}

/* Small iOS device fixes */
@media screen and (max-width: 390px) {
  .search-select {
    font-size: 16px;
    padding-left: 2.75rem;
  }
}

@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}
</style>
  