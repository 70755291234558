<template>
  <div class="mock-interview">
    <!-- Background container for full width -->
    <div class="hero-background"></div>

    <!-- Hero Image -->
    <div class="hero-image">
      <img src="@/assets/Interview_Hero.png" alt="Interview Hero">
    </div>

    <!-- Centered Hero Text -->
    <div class="hero-text">
      <h1>Mock Interview</h1>
      <p>Simulate an interview based on your resume and the job description using AI.</p>
    </div>

    <!-- Job Description and Resume Input -->
    <div v-if="!sessionStarted" class="form-group">
      <h2>Job Description</h2>
      <textarea 
        v-model="jobDescription" 
        placeholder="Enter the job description here" 
        rows="5" 
        @input="checkJobDescriptionWordCount">
      </textarea>
      <!-- Word count display and warning -->
      <p :class="{'word-limit-warning': jobDescriptionWordCount > 600, 'word-count': jobDescriptionWordCount <= 600}">
        {{ jobDescriptionWordCount }}/600
      </p>
      <p v-if="jobDescriptionWordCount > 600" class="word-limit-warning">
        The job description exceeds 600 words. Please shorten it.
      </p>

      <h2>Resume Text (Optional)</h2>
      <textarea 
        v-model="resumeText" 
        placeholder="Paste your resume text here (optional)" 
        rows="5" 
        @input="checkResumeTextWordCount">
      </textarea>
      <!-- Word count display and warning for resume text -->
      <p :class="{'word-limit-warning': resumeTextWordCount > 600, 'word-count': resumeTextWordCount <= 600}">
        {{ resumeTextWordCount }}/600
      </p>
      <p v-if="resumeTextWordCount > 600" class="word-limit-warning">
        The resume text exceeds 600 words. Please shorten it.
      </p>

      <div class="button-container">
        <button @click="startInterview" :disabled="!jobDescription || jobDescriptionWordCount > 600 || resumeTextWordCount > 600 || isLoading">
          <span v-if="!isLoading">Start Interview</span>
          <span v-else>Starting{{ loadingDots }}</span>
        </button>
      </div>
    </div>

    <!-- Chat Interface -->
    <div v-if="sessionStarted" class="chat-interface">
      <div class="chat-log">
        <div v-for="(message, index) in chatHistory" :key="index" :class="['chat-bubble', message.role]">
          <div class="message-content">
            <strong>{{ message.role === 'user' ? 'You' : 'Interviewer' }}:</strong>
            <p v-html="formatMessage(message.content)"></p>
          </div>
        </div>
      </div>

      <div class="chat-input">
        <textarea v-model="userResponse" placeholder="Enter your response here" rows="3"></textarea>
        <button @click="sendResponse" :disabled="!userResponse || isLoading">
          <span v-if="!isLoading">Send Response</span>
          <span v-else>Sending{{ loadingDots }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MockInterview",
  data() {
    return {
      jobDescription: "", // For job description input
      resumeText: "", // Resume text input (optional)
      userResponse: "", // User's response to the current question
      sessionId: null, // Session ID for tracking the conversation
      chatHistory: [], // To store the conversation history
      sessionStarted: false, // To track if the interview session has started
      isLoading: false, // Control loading state
      jobDescriptionWordCount: 0, // Word count for job description
      resumeTextWordCount: 0, // Word count for resume text
      loadingCounter: 0, // counter for the number of dots
      loadingInterval: null
    };
  },
  computed: {
    loadingDots() {
      const dotsCount = (this.loadingCounter % 3) + 1;
      return ".".repeat(dotsCount);
    }
  },
  methods: {
    // Method to check the word count of the job description
    checkJobDescriptionWordCount() {
      this.jobDescriptionWordCount = this.jobDescription
        .split(/\s+/)
        .filter(word => word.length > 0).length;
    },

    // Method to check the word count of the resume text
    checkResumeTextWordCount() {
      this.resumeTextWordCount = this.resumeText
        .split(/\s+/)
        .filter(word => word.length > 0).length;
    },

    startLoading() {
      if (this.loadingInterval) clearInterval(this.loadingInterval);
      this.loadingInterval = setInterval(() => {
        this.loadingCounter++;
      }, 500);
    },

    stopLoading() {
      clearInterval(this.loadingInterval);
      this.loadingInterval = null;
      this.loadingCounter = 0;
    },

    async startInterview() {
      if (this.jobDescriptionWordCount > 600 || this.resumeTextWordCount > 600) {
        alert('Either the job description or resume text exceeds the 600-word limit. Please shorten it.');
        return;
      }

      this.isLoading = true;
      this.startLoading();
      try {
        const requestData = {
          jobDescription: this.jobDescription,
          resumeText: this.resumeText || ""
        };

        const response = await fetch("https://mock-interview-service-923505374158.us-central1.run.app/mock-interview", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestData),
        });

        const responseData = await response.json();
        if (responseData.error) {
          alert(responseData.error);
        } else {
          this.sessionId = responseData.sessionId;
          this.chatHistory.push({ role: "interviewer", content: responseData.assistantResponse });
          this.sessionStarted = true;
        }
      } catch (error) {
        console.error("Error starting interview:", error);
        alert("An error occurred while starting the interview.");
      } finally {
        this.isLoading = false;
        this.stopLoading();
      }
    },

    async sendResponse() {
      if (!this.userResponse) return;
      this.isLoading = true;
      this.startLoading();
      try {
        const requestData = {
          jobDescription: this.jobDescription,
          userResponse: this.userResponse,
          sessionId: this.sessionId
        };

        const response = await fetch("https://mock-interview-service-923505374158.us-central1.run.app/mock-interview", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestData),
        });

        const responseData = await response.json();
        if (responseData.error) {
          alert(responseData.error);
        } else {
          // Add user's response and assistant's new response to the chat history
          this.chatHistory.push({ role: "user", content: this.userResponse });
          this.chatHistory.push({ role: "interviewer", content: responseData.assistantResponse });
          this.userResponse = ""; // Clear user's input
        }
      } catch (error) {
        console.error("Error sending response:", error);
        alert("An error occurred while sending your response.");
      } finally {
        this.isLoading = false;
        this.stopLoading();
      }
    },

    formatMessage(message) {
      // Replace instances of double asterisks (**) with <strong> tags for bold formatting
      return message
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
        .replace(/\*\*Feedback:\*\*/g, "<br><strong>Feedback:</strong>")
        .replace(/\*\*2\.\s/g, "<br><br><strong>Next Question:</strong><br>")
        .replace(/\n/g, "<br>");
    }
  }
};
</script>

<style scoped>
.hero-background {
  background-color: #F3EDE2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  display: flex;
  justify-content: center;
}

.hero-image {
  width: 20vw;
  margin: 0 auto 20px auto;
  position: relative;
  z-index: 1;
}

.hero-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

.hero-text {
  text-align: center;
  margin-bottom: 30px;
  font-family: 'FixelDisplay', sans-serif;
}

.mock-interview {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fbf7f0;
  width: 60vw;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'FixelDisplay', sans-serif;
}

textarea {
  display:block;
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 10px;
  resize: none;
  min-height: 100px;
  margin: 0 auto;
  font-family: 'FixelDisplay', sans-serif;
}

button {
  background-color: #E07B3C;
  color: #fff;
  padding: 12px 30px;
  border-radius: 30px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease;
  font-family: 'FixelDisplay', sans-serif;
}

button:hover {
  background-color: #D2691E;
}

button:disabled {
  background-color: #999;
}

.chat-interface {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #f9f9f9;
}

.chat-log {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
  font-family: 'FixelDisplay', sans-serif;
}

.chat-bubble {
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  max-width: 70%;
  word-wrap: break-word;
}

.user {
  background-color: #007aff;
  color: white;
  align-self: flex-end;
  text-align: right;
  margin-left: auto;
}

.interviewer {
  background-color: #e5e5ea;
  color: black;
  align-self: flex-start;
  text-align: left;
  margin-right: auto;
}

.chat-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  font-family: 'FixelDisplay', sans-serif;
}

.word-count {
  font-size: 0.9rem;
  color: grey;
}

.word-limit-warning {
  color: red;
  font-size: 0.9rem;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .about {
    padding: 10px;
  }

  .hero-image {
    width: 50vw;
  }

  .hero-content {
    font-size: 1rem;
  }

  .hero-title {
    font-size: 1.8rem;
  }

  .content-section {
    width: 80%;
    padding: 15px;
    margin: 0 auto 15px;
  }

  .container {
    width: 100%;
    padding: 15px;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .section-text {
    font-size: 1rem;
    line-height: 1.5;
  }

  .feature-list {
    width: 90%;
    margin: 0 auto;
  }

  .feature-list li {
    margin-bottom: 20px;
  }

  .feature-list i {
    font-size: 1.5rem;
  }

  .feature-list strong {
    font-size: 1.1rem;
  }

  .feature-list span {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .hero-image {
    width: 70vw;
  }

  .hero-title {
    font-size: 1.5rem;
  }

  .content-section {
    width: 90%;
  }

  .feature-list {
    width: 100%;
  }
}
</style>
