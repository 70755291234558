<template>
  <div class="apply-job">
    <!-- Hero Section -->
    <header v-if="job" class="hero">
      <div class="hero-content">
        <div class="hero-preheading">Apply For:</div>
        <h1 class="hero-title">{{ job.title }}</h1>
        <p class="hero-subtitle"><span class="hero-label">Company:</span> {{ job.company }}</p>
        <p class="hero-location"><span class="hero-label">Location:</span> {{ job.city }}, {{ job.state }}</p>
      </div>
    </header>

    <!-- Loading State -->
    <div v-if="loading" class="loading-state">
      Loading job details...
    </div>
    <!-- Error State -->
    <div v-else-if="error" class="error-state">
      {{ error }}
    </div>

    <!-- Job Details and Application Form -->
    <div v-else class="apply-job__container">
      <!-- Job Description Section -->
      <section v-if="job" class="job-description-section">
        <div class="job-description" v-html="formatDescription(job.description)"></div>
      </section>

      <!-- Application Form -->
      <section v-if="job" class="application-section">
        <h2 class="section-title">Submit Your Application</h2>
        <form @submit.prevent="submitApplication" class="application-form">
          <!-- Resume Upload -->
          <div class="form-field resume-upload">
            <label for="resumeInput" class="resume-label">Upload Resume<span class="required">*</span></label>
            <div class="file-input-wrapper">
              <div class="custom-file-upload">
                <div class="file-upload-content">
                  <i class="fas fa-cloud-upload-alt upload-icon"></i>
                  <div class="upload-text">
                    <p>Drag and drop your resume here</p>
                    <p class="upload-subtext">or click to browse files</p>
                    <p class="file-types">Supported formats: PDF, DOCX</p>
                  </div>
                </div>
              </div>
              <input 
                id="resumeInput" 
                type="file" 
                class="file-input" 
                @change="handleFileUpload" 
                accept=".pdf,.docx" 
                required
              />
            </div>
            <div v-if="resumeFile" class="file-info">
              <i class="fas fa-file-alt file-icon"></i>
              <span class="selected-filename">{{ resumeFile.name }}</span>
            </div>
            <button
              type="button"
              class="smart-fill-btn"
              @click.prevent="parseResume"
              :disabled="!resumeFile || parsing"
              title="Uses AI to auto-fill the application fields from your resume."
            >
              <i v-if="parsing" class="fas fa-spinner fa-spin"></i>
              <span v-else>
                <i class="fas fa-magic"></i>
                Auto Fill with AI
              </span>
            </button>
            <p class="button-description">
              Let AI help fill in the application fields from your resume.
            </p>
          </div>

          <!-- Common Required Fields -->
          <div class="form-field">
            <label>First Name<span class="required">*</span></label>
            <input v-model="applicant.firstName" required />
          </div>
          <div class="form-field">
            <label>Last Name<span class="required">*</span></label>
            <input v-model="applicant.lastName" required />
          </div>
          <div class="form-field">
            <label>Email<span class="required">*</span></label>
            <input type="email" v-model="applicant.email" required />
          </div>
          <div class="form-field">
            <label>Phone Number<span class="required">*</span></label>
            <input type="tel" v-model="applicant.phone" required />
          </div>

          <!-- Additional Questions -->
          <div v-for="question in questions" :key="question.id" class="form-field">
            <label :for="question.id">
              {{ question.label }}
              <span v-if="question.required" class="required">*</span>
            </label>
            <template v-if="question.type === 'short-answer'">
              <input
                :id="question.id"
                v-model="applicant.additionalAnswers[question.id]"
                :required="question.required"
              />
            </template>
            <template v-else-if="question.type === 'long-answer'">
              <textarea
                :id="question.id"
                v-model="applicant.additionalAnswers[question.id]"
                :required="question.required"
              ></textarea>
            </template>
            <template v-else-if="question.type === 'select'">
              <div class="select-wrapper">
                <select
                  :id="question.id"
                  v-model="applicant.additionalAnswers[question.id]"
                  :required="question.required"
                >
                  <option value="" disabled selected>Select an option</option>
                  <option
                    v-for="option in question.options"
                    :key="option"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
            </template>
          </div>

          <button type="submit" class="cta-btn">Submit Application</button>
        </form>
      </section>
    </div>

    <!-- Pop-up Modal for Resume Parse Success -->
    <transition name="fade">
      <div v-if="showParsePopup" class="modal-overlay" @click="closeParseModal">
        <div class="modal-content" @click.stop="">
          <h2>Resume Parsed</h2>
          <p>We have successfully parsed your resume. Relevant fields have been populated.</p>
          <button @click="closeParseModal">OK</button>
        </div>
      </div>
    </transition>

    <!-- Pop-up Modal for Application Submission Success -->
    <transition name="fade">
      <div
        v-if="showApplicationSuccessPopup"
        class="modal-overlay"
        @click="closeApplicationSuccessModal"
      >
        <div class="modal-content" @click.stop="">
          <h2>Application Submitted</h2>
          <p>Your application has been successfully submitted. Thank you!</p>
          <button @click="closeApplicationSuccessModal">OK</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { db, storage } from "@/firebase";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { marked } from "marked";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "ApplyJob",
  data() {
    return {
      job: null,
      jobDocId: null,
      applicant: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        additionalAnswers: {},
      },
      resumeFile: null,
      loading: true,
      error: null,
      questions: [],
      parsing: false,                // state to indicate parsing in progress
      showParsePopup: false,         // controls visibility of the parse success pop-up
      showApplicationSuccessPopup: false // controls visibility of the application success pop-up
    };
  },
  created() {
    console.log("ApplyJob component created");
    console.log("Received job docId:", this.$route.params.docId);
    this.initPage().then(() => {
      this.injectStructuredData();
    });
  },
  methods: {
    validateForm() {
      const requiredFields = [
        this.applicant.firstName,
        this.applicant.lastName,
        this.applicant.email,
        this.applicant.phone,
      ];

      if (requiredFields.some((field) => !field.trim())) {
        alert("Please fill in all required fields.");
        return false;
      }

      if (!this.resumeFile) {
        alert("Please upload your resume.");
        return false;
      }

      return true;
    },

    async submitApplication() {
      if (!this.validateForm()) {
        return;
      }

      this.loading = true;

      try {
        const applicationId = uuidv4();
        console.log("Generated Application ID:", applicationId);

        if (!this.jobDocId) {
          console.error("Firestore document ID for the job is undefined");
          alert("An error occurred. Please refresh the page and try again.");
          this.loading = false;
          return;
        }

        // Upload Resume to Firebase Storage
        const storageRef = ref(
          storage,
          `unauthenticated_resumes/${this.jobDocId}/applicants/${applicationId}/${this.resumeFile.name}`
        );
        await uploadBytes(storageRef, this.resumeFile);
        console.log(
          `Resume uploaded to: unauthenticated_resumes/${this.jobDocId}/applicants/${applicationId}/${this.resumeFile.name}`
        );

        // Get Download URL for Resume
        const resumeUrl = await getDownloadURL(storageRef);

        // Prepare Application Data
        const applicationData = {
          applicationId,
          appliedOn: new Date(),
          firstName: this.applicant.firstName,
          lastName: this.applicant.lastName,
          email: this.applicant.email,
          phone: this.applicant.phone,
          jobId: this.job.id,
          answers: this.applicant.additionalAnswers,
          resumeUrl,
        };

        console.log("Saving application data to Firestore:", applicationData);
        const applicantsRef = collection(db, "jobs", this.jobDocId, "applicants");
        await setDoc(doc(applicantsRef, applicationId), applicationData);
        console.log("Application data saved successfully!");

        // Show success modal instead of alert
        this.showApplicationSuccessPopup = true;
        this.resetForm();
      } catch (error) {
        console.error("Error submitting application:", error);
        alert(
          "An error occurred while submitting your application. Please try again."
        );
      } finally {
        this.loading = false;
      }
    },

    async initPage() {
      await this.fetchJob();
      await this.fetchQuestions();
    },

    injectStructuredData() {
      const structuredData = this.generateStructuredData();

      if (!Object.keys(structuredData).length) {
        console.error("No structured data to inject.");
        return;
      }

      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.textContent = JSON.stringify(structuredData);
      document.head.appendChild(script);

      console.log("Structured data injected:", structuredData);
    },

    generateStructuredData() {
      if (!this.job) return {};

      // Convert timestamp to ISO 8601 format
      const datePostedISO = new Date(
        this.job.datePosted?.seconds * 1000
      ).toISOString();
      const validThroughISO =
        this.job.validThrough ||
        new Date(new Date().setDate(new Date().getDate() + 30)).toISOString();

      const structuredData = {
        "@context": "https://schema.org/",
        "@type": "JobPosting",
        title: this.job.title || "Unknown Job Title",
        description: this.job.description || "No description provided.",
        datePosted: datePostedISO,
        validThrough: validThroughISO,
        employmentType: this.job.employmentType || "FULL_TIME",
        hiringOrganization: {
          "@type": "Organization",
          name: this.job.company || "Unknown Company",
          sameAs: "https://www.fylterjobs.com",
        },
        jobLocation: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            streetAddress: this.job.streetAddress || "N/A",
            addressLocality: this.job.city || "Unknown City",
            addressRegion: this.job.state || "Unknown State",
            postalCode: this.job.zip || "00000",
            addressCountry: {
              "@type": "Country",
              name: "US",
            },
          },
        },
        baseSalary: {
          "@type": "MonetaryAmount",
          currency: "USD",
          value: {
            "@type": "QuantitativeValue",
            value: this.job.salary || 0,
            unitText: "YEAR",
          },
        },
        applyUrl: `${window.location.origin}/apply/${this.jobDocId}`,
      };

      // Add jobLocationType only if the job is remote
      if (this.job.isRemote) {
        structuredData.jobLocationType = "TELECOMMUTE";
      }

      return structuredData;
    },

    async fetchJob() {
      try {
        const jobDocId = this.$route.params.docId;
        console.log("Fetching job with document ID:", jobDocId);

        const jobDocRef = doc(db, "jobs", jobDocId);
        const jobDoc = await getDoc(jobDocRef);

        if (jobDoc.exists()) {
          this.jobDocId = jobDocId;
          this.job = { id: jobDocId, ...jobDoc.data() };
          console.log("Fetched job data:", this.job);
        } else {
          this.error = "Job not found.";
          console.error("No document found with the doc ID:", jobDocId);
        }
      } catch (error) {
        this.error = "Error fetching job details.";
        console.error("Error fetching job:", error);
      } finally {
        this.loading = false;
      }
    },

    async fetchQuestions() {
      if (!this.jobDocId) return;
      try {
        const questionsRef = collection(db, "jobs", this.jobDocId, "questions");
        const snapshot = await getDocs(questionsRef);
        this.questions = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    },

    handleFileUpload(event) {
      this.resumeFile = event.target.files[0];
      console.log("Resume file selected:", this.resumeFile);
    },

    async parseResume() {
      if (!this.resumeFile) {
        alert("Please upload your resume before parsing.");
        return;
      }

      this.parsing = true;
      console.log(
        "Initiating parseResume. File name:",
        this.resumeFile.name,
        "Size:",
        this.resumeFile.size,
        "Questions:",
        this.questions
      );

      try {
        const formData = new FormData();
        formData.append("resume", this.resumeFile);
        formData.append("questions", JSON.stringify(this.questions));

        console.log("Making fetch request to parsing service with form data...");
        const response = await fetch(
          "https://resume-parsing-service-923505374158.us-central1.run.app/parse-resume",
          {
            method: "POST",
            body: formData,
          }
        );

        console.log("Response received:", response);

        if (!response.ok) {
          console.error(
            "Response not OK. Status:",
            response.status,
            response.statusText
          );
          const errorText = await response.text();
          console.error("Error response body:", errorText);
          throw new Error("Resume parsing request failed.");
        }

        const data = await response.json();

        if (data.parsedData) {
          const parsed = data.parsedData;

          if (parsed.firstName) this.applicant.firstName = parsed.firstName;
          if (parsed.lastName) this.applicant.lastName = parsed.lastName;
          if (parsed.email) this.applicant.email = parsed.email;
          if (parsed.phone) this.applicant.phone = parsed.phone;

          if (parsed.answers && typeof parsed.answers === "object") {
            for (const [qid, ans] of Object.entries(parsed.answers)) {
              this.applicant.additionalAnswers[qid] = ans;
            }
          }

          // Instead of alert, show the pop-up
          this.showParsePopup = true;
        } else if (data.error) {
          console.error("Backend returned an error:", data.error);
          alert(`Error parsing resume: ${data.error}`);
        } else {
          console.warn("No parsedData or error field found in response:", data);
          alert("No data returned. Please try another resume.");
        }
      } catch (error) {
        console.error("Error parsing resume:", error);
        alert("An error occurred while parsing the resume. Please try again.");
      } finally {
        this.parsing = false;
      }
    },

    resetForm() {
      this.applicant = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        additionalAnswers: {},
      };
      this.resumeFile = null;
    },

    formatDescription(description) {
      if (!description) return "";

      const formattedText = description
        .replace(/\\n\\n/g, "\n\n")
        .replace(/\\n/g, "\n")
        .replace(/\*\*/g, "**");

      marked.setOptions({
        breaks: true,
        gfm: true,
      });

      return marked(formattedText);
    },

    closeParseModal() {
      this.showParsePopup = false;
    },
    closeApplicationSuccessModal() {
      this.showApplicationSuccessPopup = false;
    },
  },
};
</script>

<style scoped>
/* General Styles */
.apply-job {
  text-align: center;
  padding: 0;
  margin: 0;
  background-color: #f3ede2;
  width: 100%;
}

/* Hero Section */
.hero {
  background-color: #f3ede2;
  text-align: left;
  color: #333;
  padding: 50px 0;
  width: 100%;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-preheading {
  font-size: 1.1rem;
  margin-bottom: 5px;
  color: #666;
}

.hero-title {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 10px;
  color: #fc7115;
  line-height: 1.2;
  text-decoration: underline;
}

.hero-subtitle {
  margin: 5px 0;
  color: #666;
  font-size: 1.1rem;
}

.hero-location {
  margin-top: 8px;
  color: #666;
  font-size: 1.1rem;
}

.hero-subtitle span:not(.hero-label),
.hero-location span:not(.hero-label) {
  color: #666;
  font-style: normal;
}

.resume-upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.custom-file-upload {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.upload-button {
  background-color: #fc7115;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.upload-button:hover {
  background-color: #e35d0a;
}

.upload-button i {
  margin-right: 5px;
}

.file-name {
  margin-left: 15px;
  color: #555;
  font-style: italic;
}

.smart-fill-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
}

.smart-fill-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.smart-fill-btn:hover:not(:disabled) {
  background-color: #0056b3;
}

.button-description {
  font-size: 0.9rem;
  color: #666;
  margin-top: 5px;
}

/* Loading and Error States */
.loading-state,
.error-state {
  text-align: center;
  font-size: 1.25rem;
  color: #555;
  padding: 20px;
}

/* Job Description Section */
.job-description-section {
  background-color: #ffffff;
  padding: 40px 20px;
}

.job-description {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  color: #555;
  line-height: 1.6;
}

/* Application Form Section */
.application-section {
  background-color: #fbf7f0;
  padding: 40px 20px;
}

.section-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #fc7115;
}

.application-form {
  max-width: 600px;
  margin: 0 auto;
}

.form-field {
  margin-bottom: 20px;
  text-align: left;
}

.form-field label {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
}

.form-field input,
.form-field textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

select {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #fff;
  color: #333;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  box-sizing: border-box;
}

.select-wrapper::after {
  content: "▼";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #333;
  pointer-events: none;
}

.cta-btn {
  background-color: #fc7115;
  color: white;
  padding: 15px 30px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.cta-btn:hover {
  background-color: #e35d0a;
}

/* Tooltip for Button */
.form-field button[title] {
  position: relative;
}

button[title]:hover::after {
  content: attr(title);
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  white-space: nowrap;
  z-index: 10;
}

.resume-upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
  width: 100%;
}

.resume-label {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 12px;
  color: #333;
}

.file-input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}

.file-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
}

.custom-file-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
  min-height: 100px;
  width: 100%;
}

.custom-file-upload:hover {
  border-color: #fc7115;
  background-color: #fff5ef;
}

.file-upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.upload-icon {
  font-size: 24px;
  color: #fc7115;
  margin-bottom: 8px;
}

.upload-text {
  font-size: 1rem;
  color: #666;
  text-align: center;
}

.file-name {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #333;
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.required {
  color: red;
  margin-left: 4px;
}

/* Modal Styles for Popups */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 80%;
  text-align: center;
}

.modal-content button {
  background-color: #fc7115;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 20px;
}

.modal-content button:hover {
  background-color: #e35d0a;
}

/* Fade Transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .hero-title {
    font-size: 1.25rem;
  }
  .hero-subtitle {
    font-size: 1.1rem;
  }
  .job-description {
    padding: 0 15px;
  }
  .application-form {
    padding: 0 15px;
  }
}
</style>
