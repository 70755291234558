<template>
  <div class="app-download">
    <div class="app-download__content">
      <h2 class="app-download__title">It's even better on the app!</h2>
      <p class="app-download__subtitle">
        Search Smarter, Apply Effortlessly.
      </p>
      <div class="app-download__buttons">
        <!-- iOS App Store Button -->
        <a 
          href="https://apps.apple.com/us/app/fylter-jobs-job-search/id6581483279?ref=fylterjobs" 
          target="_blank" rel="noopener noreferrer"
          class="app-download__link"
        >
          <img 
            src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" 
            alt="Download on the App Store" 
            class="app-download__badge"
          />
        </a>

<!-- Android Play Store Button -->
<a 
  href="https://play.google.com/store/apps/details?id=net.fylter.app" 
  target="_blank" rel="noopener noreferrer"
  class="app-download__link"
>
  <img 
    src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/512px-Google_Play_Store_badge_EN.svg.png" 
    alt="Get it on Google Play" 
    class="app-download__badge"
  />
</a>

      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'AppDownload',
};
</script>

<style scoped>
.app-download {
  background-color: #f3ede2;
  padding: 40px 20px;
  text-align: center;
}

.app-download__content {
  max-width: 600px;
  margin: 0 auto;
}

.app-download__title {
  font-size: 1.75rem;
  font-weight: 700;
  color: #fc7115;
  margin-bottom: 0.5rem;
  font-family: 'FixelTextExtraBold', sans-serif; 
}

.app-download__subtitle {
  font-size: 1rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.app-download__buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.app-download__badge {
  height: 50px;
  transition: transform 0.2s ease;
}

.app-download__badge:hover {
  transform: scale(1.05);
}
</style>
